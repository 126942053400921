body {
  margin: 0;
  display: grid;
  min-height: 100vh;
  width: 100%;
  font-family: Manrope, sans-serif;
  background-color: #f5f5f5;
}

textarea {
  border: 1px solid #cccccc;
  border-radius: 8px;
  font-family: Manrope, sans-serif;
  padding: 16px;
  margin-top: 16px !important;
}

h2 {
  margin-top: 0;
}

main {
  margin: auto;
  place-self: center;
  max-width: 600px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 1px 10px 14px -6px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 1px 10px 14px -6px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1px 10px 14px -6px rgba(0, 0, 0, 0.4);
}

button {
  padding: 12px 16px;
  border: none;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: #007737;
  cursor: pointer;
  font-family: Manrope, sans-serif;
}

section {
  padding: 24px 0;
  background-color: #ffffff;
}

.linear-scale {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
}

.linear-scale-col {
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
}

@media (max-width: 420px) {
  .linear-scale {
    flex-direction: column;
  }

  .linear-scale-col {
    flex-direction: row;
    padding: 8px 0;
  }

  .header-image {
    text-align: center;
  }
}

.footer-text {
  font-family: sans-serif;
  font-size: 10px;
  line-height: 20px;
  color: #555555;
  text-align: center;
}

.input-label {
  font-family: sans-serif;
  font-size: 14px;
  color: #555555;
}
